 
  
 
.image-gallery-slide img {
  width: 100%;          /* Make the image take full width of its container */
  height: 400px;        /* Set a fixed height for all images */
  object-fit: cover;    /* Ensure the image covers the entire area without distortion */
}

@media (max-width: 768px) {
  .image-gallery-slide img {
    width: 100%;          /* Make the image take full width of its container */
    height: 200px;        /* Set a fixed height for all images */
    object-fit: cover;    /* Ensure the image covers the entire area without distortion */
  }
}