 

.animated-container {
  opacity: 0;
  transform: translateX(-100%);
  animation: slideIn 1.5s forwards;
}
 

@keyframes slideIn {
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

 