body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::-webkit-scrollbar{
  width: 8px !important;
}
::-webkit-scrollbar-track {
  background: #F5E6F5;
  border-radius: 6px
}
::-webkit-scrollbar-thumb {
  background: #77C3EC;
  border-radius: 6px
}

.sd-page,
.sd-body__page {
  min-width: 100% !important;
}

.sd-row.sd-clearfix.sd-page__row > div {
  min-width: 100% !important;
}